import React from 'react'
import dynamic from 'next/dynamic'
import {useRouter} from 'next/router'

const Coupon = dynamic(() => import('./Coupon'), {ssr: false})

export default function CouponWrapper() {
  const router = useRouter()
  const couponCode = router.query.coupon ?? router.query.cupon

  if (!couponCode || couponCode === '') return null

  return <Coupon couponCode={Array.isArray(couponCode) ? couponCode[0] : couponCode} />
}
