import gql from 'graphql-tag'

export default gql`
  fragment timeFieldPreferences on Preference {
    _id
    deliveryType
    timeLabel
    time
    itsStoreOpenAtTime
    placeId
    menuId
    tableName
    directions {
      duration
    }
    website {
      _id
      name
    }
    store {
      _id
      name
      schedule {
        itsOpenNow: itsAvailableAt(websiteId: $websiteId)
      }
      schedulingOrdersDays
      minimumAnticipationDays
      currentPreparationDuration
      currentDeliveryDuration
      timeFormat
      nextAvailableDate
      availableScheduleDaysOptions {
        label
        value
      }
    }
  }
`
