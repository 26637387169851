import React from 'react'

export default function JustoLogo({style}) {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 780.4 224.7"
      style={style}
      xmlSpace="preserve">
      <title>logos_justo</title>
      <path
        className="st0"
        d="M311.6,171.3c-11.1,0-19.3-2.2-27-8c-2.2-1.6-2.7-4.8-1-7c0.2-0.3,0.4-0.5,0.6-0.7l8.4-7.8
	c1.7-1.6,4.4-1.8,6.3-0.4c3.1,2.3,6.7,3.8,12.6,3.8c11.7,0,19.3-6.5,19.3-19.7V61c0-3.1,2.5-5.6,5.6-5.6l0,0h12.7
	c3.1,0,5.6,2.5,5.6,5.6l0,0v71.6C354.9,157.9,334.4,171.3,311.6,171.3z"
      />
      <path
        className="st0"
        d="M425.2,171.3c-25.2,0-45.2-16-45.2-40.4V60.7c0-2.9,2.3-5.3,5.3-5.3c0,0,0,0,0,0h13.4c2.9,0,5.3,2.4,5.3,5.3
	c0,0,0,0,0,0v69.5c0,13.2,8.3,21.2,21.2,21.2s21.4-7.9,21.4-21.2V60.7c0-2.9,2.4-5.3,5.3-5.3c0,0,0,0,0,0H465c2.9,0,5.3,2.4,5.3,5.3
	c0,0,0,0,0,0V131C470.3,155.3,450.3,171.3,425.2,171.3z"
      />
      <path
        className="st0"
        d="M530.8,171.3c-17,0-30.3-3.1-41.7-12.5c-1.5-1.3-1.8-3.6-0.5-5.1c0.1-0.1,0.2-0.2,0.3-0.3l9.7-9.1
	c1.6-1.5,4.1-1.6,5.9-0.3c7.4,5.4,16.7,7.2,26.7,7.2c14.1,0,21.7-5,21.7-14.2c0-4-1.2-7.4-3.8-9.7c-2.4-2.1-5.2-3.2-10.9-4
	l-14.8-1.9c-10.5-1.5-18.3-4.5-23.8-9.5c-5.8-5.5-8.8-12.9-8.8-22.6c0-20.5,16.2-34.9,42.7-34.9c15.2,0,26.6,3.1,36.4,10.7
	c1.6,1.2,1.9,3.5,0.6,5.1c-0.1,0.2-0.3,0.3-0.4,0.4l-9.3,8.6c-1.6,1.5-4,1.6-5.8,0.3c-7.1-4.9-15.1-5.6-22.2-5.6
	c-12.8,0-19,6.6-19,14.5c-0.1,3.1,1.3,6.2,3.6,8.2c3.3,2.6,7.2,4.1,11.4,4.5l14.5,1.9c11.2,1.5,18.3,4.4,23.4,8.9
	c6.6,5.8,9.5,14.2,9.5,24.4C576.3,158.8,556.5,171.3,530.8,171.3z"
      />
      <path
        className="st0"
        d="M645.8,75.4v90c0,2.7-2.2,4.9-4.9,4.9h-14.2c-2.7,0-4.9-2.2-4.9-4.9v-90h-27.1c-2.7,0-4.9-2.2-4.9-4.9V60.3
	c0-2.7,2.2-4.9,4.9-4.9h78.2c2.7,0,4.9,2.2,4.9,4.9v10.2c0,2.7-2.2,4.9-4.9,4.9H645.8z"
      />
      <path
        className="st0"
        d="M768.1,158.8c-8.6,8.1-19.1,12.6-33.1,12.6c-14,0-24.6-4.5-33.2-12.6c-12.4-11.6-12.1-26-12.1-45.9
	s-0.3-34.2,12.1-45.9c8.6-8.1,19.3-12.6,33.2-12.6c13.9,0,24.5,4.5,33.1,12.6c12.4,11.6,12.2,26,12.2,45.9S780.5,147.1,768.1,158.8z
	M750.3,80.6c-3.4-3.7-8.8-6.1-15.3-6.1s-12.1,2.4-15.5,6.1c-4.7,4.8-5.8,10.2-5.8,32.3s1.2,27.4,5.8,32.3c3.4,3.7,9,6.1,15.5,6.1
	s11.9-2.4,15.3-6.1c4.7-4.8,6-10.2,6-32.3S755,85.4,750.3,80.6L750.3,80.6z"
      />
      <path
        className="st0"
        d="M112.3,0C50.3,0,0,50.3,0,112.3s50.3,112.3,112.3,112.3s112.3-50.3,112.3-112.3S174.4,0,112.3,0L112.3,0z
    M112.3,23.4c38.8,0,72.1,23.3,86,56.4c3.5,8.3-0.5,17.9-8.8,21.4c-0.2,0.1-0.5,0.2-0.8,0.3c-50.1,18.5-105.1,18.2-155-0.8
	c-7.4-2.8-11.2-11.1-8.4-18.5c0-0.1,0-0.1,0.1-0.2C38.8,47.8,72.7,23.4,112.3,23.4L112.3,23.4z M112.3,200c-40,0-74-24.7-86.8-59.2
	c-2.6-7.1,4.4-14,11.8-11.7c48.8,15.2,101.2,15.2,150,0c7.4-2.4,14.4,4.6,11.8,11.7C186.4,175.3,152.3,200,112.3,200z"
      />
    </svg>
  )
}
