import React from 'react'
import Image from '@components/Image'
import {WebsiteInitialData_CachedQuery} from '@data/__generated__/types.main'

import styles from './styles.module.css'

export default function Logo(props: {
  logo: WebsiteInitialData_CachedQuery['website']['footerConfiguration']['logo']
  website: {name?: string}
}) {
  const {logo, website} = props
  return (
    <div className={styles.logo}>
      <Image
        src={logo.url}
        alt={website.name || 'Logo'}
        title={website.name || 'logo'}
        objectFit="contain"
        layout="fill"
      />
    </div>
  )
}
