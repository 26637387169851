import React, {Fragment} from 'react'
import autobind from 'autobind-decorator'
import range from 'lodash/range'
import PropTypes from 'prop-types'

import styles from './styles.module.css'

export default class SixDigitInput extends React.Component {
  static propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    autoFocus: PropTypes.bool,
    onReady: PropTypes.func,
    digits: PropTypes.number,
    errorMessage: PropTypes.node,
    regex: PropTypes.object,
    head: PropTypes.node,
    bottom: PropTypes.node
  }

  static defaultProps = {
    autoFocus: true,
    digits: 6,
    regex: /[^0-9A-Za-z]/g,
    onReady: () => {}
  }

  constructor(props) {
    super(props)
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'inputsRefs' does not exist on type 'SixD... Remove this comment to see the full error message
    this.inputsRefs = {}
    range(props.digits).forEach(index => {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'inputsRefs' does not exist on type 'SixD... Remove this comment to see the full error message
      this.inputsRefs[`input_${index}`] = React.createRef()
    })
  }

  componentDidMount() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'autoFocus' does not exist on type 'Reado... Remove this comment to see the full error message
    if (this.props.autoFocus) {
      this.focus()
    }
  }

  @autobind
  focus() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'value' does not exist on type 'Readonly<... Remove this comment to see the full error message
    if (this.props.value) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'onChange' does not exist on type 'Readon... Remove this comment to see the full error message
      this.props.onChange('')
    }
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'inputsRefs' does not exist on type 'SixD... Remove this comment to see the full error message
    this.inputsRefs[`input_0`].current.focus()
  }

  getNextIndex(value) {
    if (!value) return 0
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'digits' does not exist on type 'Readonly... Remove this comment to see the full error message
    if (value.length + 1 > this.props.digits) return 0
    return value.length
  }

  @autobind
  focusNext() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'value' does not exist on type 'Readonly<... Remove this comment to see the full error message
    const value = this.props.value
    const index = this.getNextIndex(value)
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'inputsRefs' does not exist on type 'SixD... Remove this comment to see the full error message
    this.inputsRefs[`input_${index}`].current.focus()
  }

  isReady() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'value' does not exist on type 'Readonly<... Remove this comment to see the full error message
    return (this.props.value || '').length === this.props.digits
  }

  onChange(event, index) {
    const value = event.target.value || ''
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'value' does not exist on type 'Readonly<... Remove this comment to see the full error message
    const oldValue = this.props.value || ''
    let newValue = oldValue + value
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'regex' does not exist on type 'Readonly<... Remove this comment to see the full error message
    newValue = newValue.replace(this.props.regex, '')

    // @ts-expect-error ts-migrate(2339) FIXME: Property 'digits' does not exist on type 'Readonly... Remove this comment to see the full error message
    if (newValue.length > this.props.digits) {
      const last = newValue[newValue.length - 1]
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'onChange' does not exist on type 'Readon... Remove this comment to see the full error message
      this.props.onChange(last)
    } else {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'onChange' does not exist on type 'Readon... Remove this comment to see the full error message
      this.props.onChange(newValue)
    }
    setTimeout(() => {
      if (this.isReady()) {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'inputsRefs' does not exist on type 'SixD... Remove this comment to see the full error message
        this.inputsRefs[`input_${index}`].current.blur()
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'onReady' does not exist on type 'Readonl... Remove this comment to see the full error message
        setTimeout(() => this.props.onReady(newValue), 200)
      } else {
        this.focusNext()
      }
    }, 1)
  }

  onKeyDown(event, index) {
    if (event.key === 'Backspace') {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'value' does not exist on type 'Readonly<... Remove this comment to see the full error message
      const newVal = (this.props.value || '1').slice(0, -1)
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'onChange' does not exist on type 'Readon... Remove this comment to see the full error message
      this.props.onChange(newVal)
      setTimeout(this.focusNext, 1)
    }
  }

  renderInputs() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'digits' does not exist on type 'Readonly... Remove this comment to see the full error message
    return range(this.props.digits).map(index => {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'value' does not exist on type 'Readonly<... Remove this comment to see the full error message
      const value = (this.props.value || '')[index] || ''
      return (
        <Fragment key={index}>
          <div className={styles.inputContainer}>
            <input
              value={value.toUpperCase()}
              // @ts-expect-error ts-migrate(2339) FIXME: Property 'inputsRefs' does not exist on type 'SixD... Remove this comment to see the full error message
              ref={this.inputsRefs[`input_${index}`]}
              className={styles.input}
              placeholder="•"
              onChange={event => this.onChange(event, index)}
              onKeyDown={event => this.onKeyDown(event, index)}
            />
          </div>
          {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'digits' does not exist on type 'Readonly... Remove this comment to see the full error message */}
          {index === this.props.digits / 2 - 1 ? <div className={styles.divider}>-</div> : null}
        </Fragment>
      )
    })
  }

  render() {
    const className = this.isReady() ? styles.ready : styles.container
    return (
      <>
        <div className={className}>
          {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'head' does not exist on type 'Readonly<{... Remove this comment to see the full error message */}
          {this.props.head}
          <div className={styles.inputs} onClick={this.focus}>
            {this.renderInputs()}
          </div>
          {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'bottom' does not exist on type 'Readonly... Remove this comment to see the full error message */}
          {this.props.bottom}
        </div>
        {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'errorMessage' does not exist on type 'Re... Remove this comment to see the full error message */}
        <div className="os-input-error">{this.props.errorMessage}</div>
      </>
    )
  }
}
