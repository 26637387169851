import isServerSide from '@helpers/misc/isServerSide'

const supportsNativeSmoothScroll =
  typeof document !== 'undefined' && 'scrollBehavior' in document.documentElement.style

export default function scrollTo({
  element,
  top = 0,
  left = 0
}: {
  element?: any
  top?: number
  left?: number
}) {
  if (isServerSide()) {
    return
  }
  element = element?.scrollTo && typeof element?.scrollTo === 'function' ? element : window

  if (!element) {
    return
  }

  if (supportsNativeSmoothScroll) {
    element.scrollTo({
      top,
      left,
      behavior: 'smooth'
    })
    return
  }

  try {
    element.scrollTo(left, top)
  } catch (error) {
    try {
      element.scrollTop = top
      element.scrollLeft = left
    } catch (error) {}
  }
}
