import React from 'react'
import InteligentLink from '@components/InteligentLink'
import getJustoTerms from '@i18n/helpers/terms/getJustoTerms'
import removePrefix from '@i18n/helpers/terms/removePrefix'
import values from 'lodash/values'
import {useTranslation} from 'next-i18next'

import styles from './styles.module.css'

export default function Terms(props) {
  const {countryCode, terms, titleStyle} = props
  const {t} = useTranslation('terms')

  if (!terms) return null

  const termsLinks = {}
  const justoTerms = getJustoTerms(terms)
  for (const termType in terms) {
    const [termTypeWithoutPrefix, addTerm] = removePrefix(termType, justoTerms)
    if (addTerm) {
      const title = terms[termType].title
      termsLinks[termType] = {
        path: `/documentos-legales/${termTypeWithoutPrefix}`,
        title: title || t(`${termTypeWithoutPrefix}.title`)
      }
    }
  }

  const termsPresent = values(termsLinks)

  if (!termsPresent) return null

  return (
    <>
      {termsPresent.map((link: {path: string; title: string}, index) => (
        <InteligentLink
          className={styles.link}
          style={titleStyle}
          key={index}
          to={link.path}
          moveScrollTo>
          {link.title}
        </InteligentLink>
      ))}
    </>
  )
}
