import React, {useEffect} from 'react'
import useKeyboardEvent from '@hooks/useKeyboardEvent'

import Bottom from './Bottom'
import Code from './Code'
import Head from './Head'
import TopText from './TopText'

import styles from './styles.module.css'

export default function ModalInner(props) {
  const {email, close} = props

  useEffect(() => {
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [])

  useKeyboardEvent('Escape', close)

  return (
    <div className={styles.container}>
      <div className={styles.modalInner}>
        <Head />
        <TopText />
        <Code email={email} onSuccess={close} />
        <Bottom email={email} close={close} />
      </div>
    </div>
  )
}
