import {PREFIX_EXT_TERMS} from '@i18n/helpers/terms/constants'

const removePrefix = (termType, justoTerms = []) => {
  const termTypeWithoutPrefix = termType.replace(PREFIX_EXT_TERMS, '')
  const hasPrefixWebsite = termType !== termTypeWithoutPrefix
  const addTerm =
    (hasPrefixWebsite && !justoTerms.includes(termTypeWithoutPrefix)) || !hasPrefixWebsite

  return [termTypeWithoutPrefix, addTerm]
}
export default removePrefix
