import React from 'react'
import ClientSuspense from '@components/ClientSuspense'
import InfoBanner from '@components/InfoBanner'
import {WebsiteInitialData_CachedQuery} from '@data/__generated__/types.main'
import isIFrame from '@helpers/misc/isIFrame'
import useIsSpecialLayout from '@hooks/useIsSpecialLayout'

import isMobile from './DownloadApp/isMobile'
import Coupon from './Coupon'
import DownloadApp from './DownloadApp'
import Footer from './Footer'
import ImportantMessage from './ImportantMessage'
import Layouts from './Layouts'
import LoginWithCode from './LoginWithCode'
import MessengerReturn from './MessengerReturn'
import SetPreferences from './SetPreferences'

import styles from './styles.module.css'

export interface WebsiteProps {
  children: React.ReactNode
  website?: WebsiteInitialData_CachedQuery['website']
}
export default function Website(props: WebsiteProps) {
  const layoutTheme = useIsSpecialLayout()
  return (
    <div>
      <ClientSuspense fallback={null}>{isMobile() ? <DownloadApp /> : null}</ClientSuspense>
      <InfoBanner />
      <ImportantMessage />
      <ClientSuspense fallback={null}>
        <MessengerReturn />
      </ClientSuspense>
      <ClientSuspense fallback={null}>
        <Coupon />
      </ClientSuspense>
      <ClientSuspense fallback={null}>
        <SetPreferences />
      </ClientSuspense>
      <Layouts website={props.website}>
        <div>
          <LoginWithCode>
            <div className={styles.child}>{props.children}</div>
          </LoginWithCode>
          {isIFrame || layoutTheme === 'chat' ? null : <Footer />}
        </div>
      </Layouts>
    </div>
  )
}
