import React from 'react'
import {useTranslation} from 'next-i18next'

import styles from './styles.module.css'

export default function Sent(props) {
  const {email} = props
  const {t} = useTranslation('website', {
    keyPrefix: 'layout.loginWithCode.modal.modalInner.bottom.sent'
  })
  return <div className={styles.container}>{t('labelSentTo', {email})}</div>
}
