import gql from 'graphql-tag'

export default gql`
  fragment updateAddressFragment on Preference {
    _id
    placeId
    storeId
    websiteId
    preferredDeliveryStoreId
    store {
      _id
      name
      fullName
      countryCode
    }
    address {
      _id
      placeId
      address
      addressLine2
      addressSecondary
      location
    }
  }
`
