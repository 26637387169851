import {useEffect,useRef} from 'react'

export default function useKeyboardEvent(combination, callback) {
  const keysToMatch = combination.toLowerCase().split('+')
  const keys = useRef({}).current
  const callbackRef = useRef(null)
  callbackRef.current = callback

  const checkEvent = () => {
    for (const keyToMatch of keysToMatch) {
      if (!keys[keyToMatch]) return
    }

    callbackRef.current()
  }

  const onKeyDown = event => {
    if (event && event.key) {
      const key = event.key.toLowerCase()
      keys[key] = true
    }
  }

  const onKeyUp = event => {
    if (event && event.key) {
      checkEvent()
      const key = event.key.toLowerCase()
      delete keys[key]
    }
  }

  useEffect(() => {
    document.addEventListener('keyup', onKeyUp)
    document.addEventListener('keydown', onKeyDown)
    return () => {
      document.removeEventListener('keyup', onKeyUp)
      document.removeEventListener('keydown', onKeyDown)
    }
  })
}
