import React from 'react'
import {useTranslation} from 'next-i18next'

import styles from './styles.module.css'

export default function TopText(props) {
  const {t} = useTranslation('website', {
    keyPrefix: 'layout.loginWithCode.modal.modalInner.topText'
  })
  return (
    <div className={styles.container}>
      <div className={styles.header}>{t('labelHeader')}</div>
      <div className={styles.text}>{t('labelText')}</div>
    </div>
  )
}
