import React from 'react'
import useWebsiteId from '@hooks/useWebsiteId'
import Container from '@packages/justo-parts/lib/components/Container'
import useInitialData from '@page-components/Layout/useInitialData'
import {useTranslation} from 'next-i18next'

import openApp from './openApp'

import styles from './styles.module.css'

export default function DownloadApp(props) {
  const websiteId = useWebsiteId()
  const {t} = useTranslation('website', {keyPrefix: 'layout.downloadApp'})
  const {website, config} = useInitialData()

  if (!website || !website.appsCodes || !website.appsCodes.length) return null
  const [code] = website.appsCodes

  if (!config.app) return null

  const {downloadAppBarMessage, playStoreURL, appStoreURL} = config.app

  if (!downloadAppBarMessage) return null
  if (!playStoreURL) return null
  if (!appStoreURL) return null

  return (
    <a
      target="blank"
      onClick={() => openApp({code, playStoreURL, appStoreURL})}
      className={styles.container}>
      <Container>
        <div className={styles.inner}>
          <div className={styles.description}>{downloadAppBarMessage}</div>
          <div className={styles.code}>{t('labelDownload')}</div>
        </div>
      </Container>
    </a>
  )
}
