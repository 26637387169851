import gql from 'graphql-tag'

import cartFragment from '../cartFragment'

import storePreferencesFragment from './storePreferencesFragment.main'
import timeFieldPreferencesFragment from './timeFieldPreferencesFragment.main'
import updateAddressFragment from './updateAddressFragment.main'

export default gql`
  fragment appOrderPreferences on Preference {
    ...timeFieldPreferences
    ...cartFragment
    ...updateAddressFragment
    _id
    websiteId
    tableName
    storeId
    placeId
    deliveryType
    paymentType
    deliverAt
    cashAmount
    nationalId
    time
    menuId
    timeLabel
    bagsRequired
    chatCommerceType
    sessionEmail
    sessionEmailHasAccount
    sessionEmailHasName
    directions {
      duration
    }
    address {
      _id
      address
    }
    store {
      ...storePreferences
    }
    orderGroup {
      _id
      tableTotal
    }
  }

  ${timeFieldPreferencesFragment}
  ${cartFragment}
  ${updateAddressFragment}
  ${storePreferencesFragment}
`
