import isServerSide from '@helpers/misc/isServerSide'
import {useApolloQuery as useQuery} from 'apollo-hooks'
import gql from 'graphql-tag'

export default function useLogo({
  darkMode,
  countryCode
}: {
  darkMode?: boolean
  countryCode?: string
}) {
  const {viewerCountryCode: result} = useQuery({
    query: gql`
      query viewerCountryCode {
        viewerCountryCode
      }
    `,
    omit: isServerSide(),
    partial: true
  })

  const viewerCountryCode = result?.viewerCountryCode ?? countryCode

  if (viewerCountryCode === 'MX') {
    if (darkMode) {
      return 'https://s3.amazonaws.com/orion-eat-app-files/orioneat-prod%2FnLqPFjwg9bDARQoPv-Getjusto_Oscuro.svg'
    }
    return 'https://s3.amazonaws.com/orion-eat-app-files/orioneat-prod%2FdNRSZqaCSK6NYKEFZ-Getjusto_Blanco.svg'
  }
  if (darkMode) return '/logo-dark.svg'
  return '/logo-white.svg'
}
