import {WebsiteInitialData_CachedQuery} from '@data/__generated__/types.main'

export default function useWebsiteLayout(website: WebsiteInitialData_CachedQuery['website']) {
  const layout = website && website.layout ? website.layout : 'default'
  const menuLayout = website && website.menuLayout ? website.menuLayout : 'v1'
  const productsLayout = website && website.productsLayout ? website.productsLayout : 'long'
  const checkoutLayout = 'v3'
  const headerLayout = website && website.headerLayout ? website.headerLayout : 'default'
  return {layout, menuLayout, productsLayout, checkoutLayout, headerLayout}
}
