import {useEffect} from 'react'
import appOrderPreferences from '@data/fragments/preferences/appOrderPreferences.main'
import useWebsiteId from '@hooks/useWebsiteId'
import {useMutate} from 'apollo-hooks'
import gql from 'graphql-tag'
import isEmpty from 'lodash/isEmpty'
import {useRouter} from 'next/router'

const updatePreferences = async ({updates, websiteId, mutate}) => {
  await mutate({
    mutation: gql`
      mutation setUserPreferencesAtLoad(
        $websiteId: ID
        $storeId: ID
        $deliveryType: ID
        $tableName: String
        $placeId: ID
      ) {
        preferences: setUserPreferences(
          websiteId: $websiteId
          deliveryType: $deliveryType
          storeId: $storeId
          tableName: $tableName
          placeId: $placeId
        ) {
          _id
          deliveryType
          storeId
          tableName
          placeId
          ...appOrderPreferences
        }
      }
      ${appOrderPreferences}
    `,
    variables: {websiteId, ...updates}
  })
}

export default function SetPreferences() {
  const router = useRouter()
  const {deliveryType, storeId, tableName, placeId} = router.query

  const websiteId = useWebsiteId()
  const mutate = useMutate()

  useEffect(() => {
    const updates: any = {}

    if (deliveryType) {
      updates.deliveryType = deliveryType
      // router.replace({pathname: window.location.pathname, query: {...query, deliveryType: ''}})
    }

    if (placeId) {
      updates.placeId = placeId
      // router.replace({pathname: window.location.pathname, query: {...query, placeId: ''}})
    }

    if (storeId) {
      updates.storeId = storeId
      // router.replace({pathname: window.location.pathname, query: {...query, storeId: ''}})
    }

    if (tableName) {
      updates.tableName = tableName
      // router.replace({pathname: window.location.pathname, query: {...query, tableName: ''}})
    }

    if (!isEmpty(updates)) {
      updatePreferences({updates, websiteId, mutate})
    }
  }, [router.query])

  return null
}
