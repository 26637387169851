import React from 'react'

import styles from './styles.module.css'

export default function SocialMedia(props) {
  const {instagram, facebook, twitter, titleStyle} = props

  return (
    <>
      {instagram || facebook || twitter ? <h3 style={titleStyle}>Redes sociales</h3> : null}
      {instagram && (
        <a className={styles.link} style={titleStyle} target="blank" href={instagram}>
          Instagram
        </a>
      )}
      {facebook && (
        <a className={styles.link} style={titleStyle} target="blank" href={facebook}>
          Facebook
        </a>
      )}
      {twitter && (
        <a className={styles.link} style={titleStyle} target="blank" href={twitter}>
          Twitter
        </a>
      )}
    </>
  )
}
