import React from 'react'
import {IoChatbubbleEllipses, IoOpenOutline} from 'react-icons/io5'
import useSessionStorageState from '@hooks/useSessionStorageState'
import Container from '@packages/justo-parts/lib/components/Container'
import getQueryParam from '@packages/justo-parts/lib/helpers/getQueryParam'

export default function MessengerReturn() {
  const sessionStateURL = useSessionStorageState('messengerReturnURL')[0]
  const queryParamURL = getQueryParam('msn_return_url')
  const url = queryParamURL || sessionStateURL
  const whatsAppColor = '#25d366'
  if (!url) return null
  return (
    <a className="block w-full text-white" href={url} style={{backgroundColor: whatsAppColor}}>
      <Container>
        <div className="flex items-center space-x-2 py-2 font-medium">
          <IoChatbubbleEllipses className="w-5" />
          <div>Volver a la conversación</div>
          <IoOpenOutline className="w-4" />
        </div>
      </Container>
    </a>
  )
}
