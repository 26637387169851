import React, {useState} from 'react'
import SixDigitSmallInput from '@components/fields/SixDigitSmallInput'
import Loading from '@components/Loading'
import useMessage from '@hooks/useMessage'
import saveJWT from '@providers/apollo/saveJWT'
import {useMutate} from 'apollo-hooks'
import gql from 'graphql-tag'

import styles from './styles.module.css'

export default function Submit(props) {
  const {email, token, onSuccess} = props
  const [value, setValue] = useState()
  const [loading, setLoading] = useState(false)
  const digits = 6
  const mutate = useMutate()
  const showMessage = useMessage()

  const loginWithCode = async code => {
    setLoading(true)
    try {
      const {session} = await mutate({
        mutation: gql`
          mutation loginWithCode($email: String, $token: String, $code: String) {
            session: loginWithCode(email: $email, token: $token, code: $code) {
              token
              refreshToken
            }
          }
        `,
        variables: {email, token, code}
      })
      saveJWT(session)
      onSuccess()
    } catch (error) {
      showMessage(error)
    }
    setLoading(false)
  }

  if (loading) {
    return (
      <div className={styles.loadingContainer}>
        <Loading></Loading>
      </div>
    )
  }

  return (
    <div>
      <SixDigitSmallInput
        value={value}
        onChange={setValue}
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ value: undefined; onChange: Dispatch<(prev... Remove this comment to see the full error message
        type={SixDigitSmallInput}
        digits={digits}
        onReady={code => loginWithCode(code)}
      />
    </div>
  )
}
