import React, {useEffect, useState} from 'react'
import Loading from '@components/Loading'
import useMessage from '@hooks/useMessage'
import {useMutate} from 'apollo-hooks'
import gql from 'graphql-tag'
import {useTranslation} from 'next-i18next'

import Submit from './Submit'

import styles from './styles.module.css'

export default function Code(props) {
  const {email, onSuccess} = props
  const [token, setToken] = useState()
  const [loading, setLoading] = useState(false)
  const {t} = useTranslation('generic')
  const showMessage = useMessage()

  const mutate = useMutate()
  const requestLoginCode = async () => {
    setLoading(true)
    try {
      const {resultToken} = await mutate({
        mutation: gql`
          mutation requestLoginCodeCodeLoginInner($email: String) {
            resultToken: requestLoginCode(email: $email)
          }
        `,
        variables: {email}
      })
      setToken(resultToken)
    } catch (error) {
      showMessage(error)
    }
    setLoading(false)
  }

  useEffect(() => {
    requestLoginCode()
  }, [])

  if (loading) {
    return (
      <div className={styles.loadingContainer}>
        <Loading></Loading>
      </div>
    )
  }

  if (token) return <Submit onSuccess={onSuccess} email={email} token={token} />

  return (
    <div className={styles.retry}>
      <a onClick={() => requestLoginCode()}>{t('fields.retry')}</a>
    </div>
  )
}
