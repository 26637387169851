import gql from 'graphql-tag'
export default gql`
  fragment storePreferences on Store {
    _id
    nextAvailableDate
    currentExtraDeliveryCost
    schedulingOrdersDays
    currentPreparationDuration
    currentDeliveryDuration
    name
    minimumAnticipationDays
    countryCode
    schedule {
      itsOpenNow: itsAvailableAt(websiteId: $websiteId)
    }
    availableScheduleDaysOptions {
      label
      value
    }
  }
`
