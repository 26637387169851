import React from 'react'
import ClientSuspense from '@components/ClientSuspense'
import Container from '@packages/justo-parts/lib/components/Container'
import useInitialData from '@page-components/Layout/useInitialData'

import useDeliveryType from './useDeliveryType.main'

import styles from './styles.module.css'

function InfoBannerInner(props) {
  const {website} = useInitialData()
  const {importantMessageTableText} = website?.navbarConfiguration
  const deliveryType = useDeliveryType()

  if (!importantMessageTableText) return null
  if (deliveryType !== 'serve') return null

  return (
    <div className={styles.container}>
      <Container>
        <div className={styles.inner}>
          <div className={styles.description}>{importantMessageTableText}</div>
        </div>
      </Container>
    </div>
  )
}

export default function InfoBanner() {
  return (
    <ClientSuspense fallback={null}>
      <InfoBannerInner />
    </ClientSuspense>
  )
}
