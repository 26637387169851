import gql from 'graphql-tag'

export default gql`
  fragment resumeCartItem on CartItem {
    _id
    isOutOfStock
    outOfStockMessage
    amount
    product {
      _id
      name
      maxPurchaseQuantity
    }
    menuId
    realPrice: unitPrice(includeDiscount: false)
    unitPrice
    description
    descriptionItems {
      title
      selections
    }
    websiteId
    comment
    modifiers {
      modifierId
      optionsIds
    }
  }
`
