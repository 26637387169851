import React from 'react'
import Container from '@packages/justo-parts/lib/components/Container'
import useInitialData from '@page-components/Layout/useInitialData'

import styles from './styles.module.css'

export default function ImportantMessage(props) {
  const {website} = useInitialData()

  const text = website?.navbarConfiguration?.importantMessageText
  if (!text) return null

  return (
    <div className={styles.container}>
      <Container>
        <div className={styles.inner}>
          <div className={styles.description}>{text}</div>
        </div>
      </Container>
    </div>
  )
}
