import React, {useState} from 'react'
import sleep from '@helpers/misc/sleep'
import Context from '@hooks/useRequestAuthenticate/Context'

import Modal from './Modal'

export default function LoginWithCode(props) {
  const [email, setEmail] = useState()

  const requestAuthenticate = async email => {
    await sleep(100)
    setEmail(email)
  }

  return (
    <>
      <Context.Provider value={requestAuthenticate}>{props.children}</Context.Provider>
      {email ? (
        <Modal
          email={email}
          close={() => {
            setEmail(null)
          }}
        />
      ) : null}
    </>
  )
}
