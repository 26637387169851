import isServerSide from './isServerSide'

function inIframe() {
  if (isServerSide()) return false
  try {
    return window.self !== window.top
  } catch (e) {
    return true
  }
}

export default inIframe()
