import {useRouter} from 'next/router'

export default function useIsSpecialLayout(): 'chat' | false {
  const router = useRouter()
  if (router.query.isChat === 'true') {
    return 'chat'
  }

  return false
}
