import React from 'react'
import ClientSuspense from '@components/ClientSuspense'
import InteligentLink from '@components/InteligentLink'
import {useLoginViaAuthWindow} from '@helpers/auth/loginViaAuth'
import useSession from '@hooks/useSession'
import useShouldUseLoyaltyV2 from '@page-components/CheckoutV3/sections/useShouldUseLoyaltyV2'
import useInitialData from '@page-components/Layout/useInitialData'
import Link from 'next/link'
import {useTranslation} from 'next-i18next'

import styles from './styles.module.css'

function DynamicLinksInner({titleStyle}) {
  const {website} = useInitialData()
  const {t} = useTranslation('website', {keyPrefix: 'layout.footer'})
  const {countryCode, disableComplaints} = website
  const loginWithAuth = useLoginViaAuthWindow('footer')
  const showJustoLoyaltyV2 = useShouldUseLoyaltyV2()
  const pointsLink = showJustoLoyaltyV2 ? '/justoloyalty' : '/canjear'
  const session = useSession()
  const isLogged = Object.keys(session).length !== 0
  return (
    <>
      <h3 style={titleStyle}>{t('labelMyAccount')}</h3>
      <Link href="/pedir" passHref className={styles.link} style={titleStyle}>
        {t('labelOrder')}
      </Link>
      {website.pointsConfiguration && (
        <Link href={pointsLink} passHref className={styles.link} style={titleStyle}>
          {website.pointsConfiguration.name}
        </Link>
      )}
      {isLogged ? (
        <>
          <Link href="/pedidos" passHref className={styles.link} style={titleStyle}>
            {t('labelMyOrders')}
          </Link>
          <Link href="/settings/addresses" passHref className={styles.link} style={titleStyle}>
            {t('labelMyAddresses')}
          </Link>
        </>
      ) : (
        <a onClick={loginWithAuth} className={styles.link} style={titleStyle}>
          {t('labelSignIn')}
        </a>
      )}

      {countryCode === 'PE' && !disableComplaints ? (
        <InteligentLink
          className={styles.bookLink}
          style={titleStyle}
          href="/reclamos"
          moveScrollTo>
          <div className={styles.rowCenter}>
            <img
              className={styles.complaintLogo}
              src="/images/libro_reclamaciones_logo.png"
              alt="Logo Chek"
            />
          </div>
        </InteligentLink>
      ) : null}
    </>
  )
}

export default function DynamicLinks({titleStyle}) {
  return (
    <ClientSuspense fallback={null}>
      <DynamicLinksInner titleStyle={titleStyle} />
    </ClientSuspense>
  )
}
