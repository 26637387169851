import React from 'react'
import {MdLock as SecureIcon} from 'react-icons/md'
import useLogo from '@hooks/useLogo'
import {useTranslation} from 'next-i18next'

import styles from './styles.module.css'

export default function Head(props) {
  const {t} = useTranslation('website', {keyPrefix: 'layout.loginWithCode.modal.modalInner.head'})
  const logoUrl = useLogo({darkMode: false})
  return (
    <div className={styles.container}>
      <div className={styles.secure}>
        <SecureIcon /> <span className={styles.secureText}>{t('labelSecure')}</span>
      </div>
      <div className={styles.logo}>
        <img src={logoUrl} alt="Logo de Justo" />
      </div>
    </div>
  )
}
